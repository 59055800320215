  .Services-sections {
    border: none;
    display: grid;
    margin: 0px;
    grid-template-columns: auto auto;
    font-family: Satoshi-Regular;
  }
  
  .Services-text {
    margin-top: 0;
    margin-bottom: 40px;
  }
  
  ul.Services-list {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }
  
  .grid-item {
    border: none;
    margin: 0px;
    padding-bottom: 20px;
    padding-left: 0;
    list-style-type: none;
  }
  
  .Services-bullet {
    height: 18px;
    margin-right: 5px;
  }

  .Services-list-item {
    display: flex;
    align-items: center;
  }

  .Services-headline-grid {
    border-top: 1px solid;
    display: grid;
    grid-template-columns: auto auto;
    font-family: Satoshi-Regular;
    text-align: left;
    cursor: pointer;
  }

  .Headline-wrapper {
    position: relative;
  }
  
  .Services-headline {
    text-align: right;
  }

  @media (min-width: 993px) {

    .Services {
        padding-top: 20vh ;
        padding-bottom: 10vh;
    }
    }
    
  
/* Mobile */
@media (max-width: 992px) {
    .Services {
        padding-top: 120px;
        padding-bottom: 20px;
      }
}