body {
  font-family: 'Satoshi-Regular';
  margin: 0;
}

@media (min-width: 993px) {
  body {
    margin: 0;
    overflow: hidden;
  }
}

@media (max-width: 992px) {
  body a:link {
      text-decoration: none;
      color: inherit;
      font-family: 'Satoshi-Regular';
    }
    
    body a:visited {
      text-decoration: none;
      color: inherit;
    }

    html {
      overscroll-behavior: none;
    }

}
