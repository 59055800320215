/* Desktop */
@media (min-width: 993px) {

  .Date-desktop {
    text-align: right;
  }

.Work {
  margin-top: 14vh;
}

.Work-intro {
  padding-bottom: 40px;
}

.Work-image-group {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  justify-content: center;
}

.Work-grid {
  border-top: 1px solid;
  display: grid;
  grid-template-columns: 300px 350px auto;
  font-family: 'Inter';
  text-align: left;
  cursor: pointer;
}

.Headline-wrapper {
  position: relative;
}

.Work-headline {
  position: absolute;
  display: inline-block;
}

.Marquee {
  opacity: 0;
}

.Headline-wrapper:hover .Marquee {
  opacity: 1;
  margin: auto;
}

.Headline-wrapper:hover > .Work-headline {
  opacity: 0;
}

/* Accordion */
.AccordionRoot {
    border-radius: 0px;
    background-color: none;
    box-shadow: none;
  }

.AccordionContent {
    overflow: hidden;
  }
  
.AccordionContent[data-state='open'] {
    animation: slideDown 300ms ease-out;
  }
  
.AccordionContent[data-state='closed'] {
    animation: slideUp 300ms ease-out;
  }
  
  @keyframes slideDown {
    from {
      height: 0;
    }
    to {
      height: var(--radix-accordion-content-height);
    }
  }
  
  @keyframes slideUp {
    from {
      height: var(--radix-accordion-content-height);
    }
    to {
      height: 0;
    }
  }

/* image carousel */
  .slider {
    position: relative;
    width: 100%;
    height: 400px;
    overflow: hidden
  }
  
  .slider a.previousButton, .slider a.nextButton {
    font-size: 22px;
    line-height: 0;
    display: block;
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    z-index: 1;
    color: currentColor;
    padding: 10px;
    text-decoration: none;
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden
  }
  
  .slider a.previousButton:not(.disabled):hover, .slider a.nextButton:not(.disabled):hover {
    filter: hue-rotate(-0.25turn);
    color: currentColor;
    cursor: pointer;
  }
  
  .slider a.previousButton {
    left: 20px;
  }
  
  .slider a.nextButton {
    right: 20px;
  }
  
  .slide {
    width: 100%;
    height: 100%;
    position: absolute;
    overflow: hidden
  }
  
  .slide.hidden {
    visibility: hidden;
  }
  
  .slide.previous {
    left: -100%;
  }
  
  .slide.current {
    left: 0;
  }
  
  .slide.next {
    left: 100%;
  }
  
  .slide.animateIn,
    .slide.animateOut {
    -webkit-transition: all 2s ease;
    transition: all 2s ease;
  }
  
  .slide.animateIn.previous,
      .slide.animateIn.next {
    left: 0;
    visibility: visible;
  }
  
  .slide.animateOut.previous {
    left: 100%;
  }
  
  .slide.animateOut.next {
    left: -100%;
  }

  /* remove animated link underline from image carousel buttons */
.slider a::after {
  background-color:transparent;
}

.Date-mobile {
  display: none;
}

.Lex-app-screenshots {
  width: 60vw;
}
}
  
/* Mobile */
@media (max-width: 992px) {

  .Work {
    padding-top: 120px;
  }

  .Work-intro {
    font-size: .85em;
  }

  .Container-mobile {
    display: flex;
    justify-content: space-between;
  }

  .Date-desktop {
    display: none;
  }

  .Work-grid {
    border-top: 1px solid;
    font-family: 'Inter';
    text-align: left;
    font-size: 14px;
  }

  .Work-image-group {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .Lex-app-screenshots {
    width: 100vw;
  }

  .Headline-wrapper {
    position: relative;
  }
  
  .Work-headline {
    display: none;
  }

  .Marquee h4 {
    margin-top: 0px;
  }

  .Container-mobile h4{
    margin-bottom: 0px;
  }
  
/* Accordion */
.AccordionRoot {
  border-radius: 0px;
  background-color: none;
  box-shadow: none;
}

.AccordionContent {
  overflow: hidden;
}

.AccordionContent[data-state='open'] {
  animation: slideDown 300ms ease-out;
}

.AccordionContent[data-state='closed'] {
  animation: slideUp 300ms ease-out;
}

@keyframes slideDown {
  from {
    height: 0;
  }
  to {
    height: var(--radix-accordion-content-height);
  }
}

@keyframes slideUp {
  from {
    height: var(--radix-accordion-content-height);
  }
  to {
    height: 0;
  }
}

/* image carousel */
.slider {
  position: relative;
  width: 100%;
  height: 230px;
  overflow: hidden
}

.slider a.previousButton, .slider a.nextButton {
  font-size: 22px;
  line-height: 0;
  display: block;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  z-index: 1;
  color: currentColor;
  padding: 10px;
  text-decoration: none;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden
}

.slider a.previousButton:not(.disabled):hover, .slider a.nextButton:not(.disabled):hover {
  filter: hue-rotate(-0.25turn);
  color: currentColor;
  cursor: pointer;
}

.slider a.previousButton {
  left: -8px;
}

.slider a.nextButton {
  right: -8px;
}

.slide {
  width: 100%;
  height: 100%;
  position: absolute;
  overflow: hidden
}

.slide.hidden {
  visibility: hidden;
}

.slide.previous {
  left: -100%;
}

.slide.current {
  left: 0;
}

.slide.next {
  left: 100%;
}

.slide.animateIn,
  .slide.animateOut {
  -webkit-transition: all 2s ease;
  transition: all 2s ease;
}

.slide.animateIn.previous,
    .slide.animateIn.next {
  left: 0;
  visibility: visible;
}

.slide.animateOut.previous {
  left: 100%;
}

.slide.animateOut.next {
  left: -100%;
}
}