/* import fonts */

@font-face {
  font-family: 'Satoshi-Bold';
  src: url('./fonts/Satoshi-Bold.woff2') format('woff2');
       font-weight: 700;
       font-style: normal;
}
@font-face {
  font-family: 'Satoshi-Regular';
  src: url('./fonts/Satoshi-Regular.woff2') format('woff2');
       font-weight: 400;
       font-style: normal;
}



/* set app-wide font styling */
h1 {
  font-family: 'Satoshi-Bold';
}

h2 {
  font-family: 'Satoshi-Bold';
}

h3 {
  font-family: 'Satoshi-Regular';
}

h4 {
  font-family: 'Satoshi-Regular';
}

p {
  font-family: 'Satoshi-Regular';
}

.App {
  min-height: 100vh;
}

/* fade components in and out */
.Show-div {
  transition: opacity 0.5s;
  opacity: 1;
}

.Hide-div {
  opacity: 0;
  pointer-events:none;
}

/* Desktop */
@media (min-width: 993px) {

/* Header */
.App {
  -ms-overflow-style: none; 
  scrollbar-width: none; 
}

.App::-webkit-scrollbar {
  display: none;
}

/* remove scrollbar */
.App-content::-webkit-scrollbar {
  display: none;
}

.App-content {
  /* center on screen, remove scrollbar */
  position: fixed;
  top: 50%;
  left: 50%;
  width: auto;
  height: auto;
  -webkit-transform: translate(-50%,-50%);
  -moz-transform: translate(-50%,-50%);
  -ms-transform: translate(-50%,-50%);
  -o-transform: translate(-50%,-50%);
  transform: translate(-50%,-50%);
  width: 60vw;
  height: 100vh;
  overflow: auto;
  -ms-overflow-style: none; 
  scrollbar-width: none;
}

.App-header {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-left: 21.44px;
  position: fixed;
}

.Header-top {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
}

.Header-left {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.Social-links-group-dropdown {
  font-size: 14px;
  margin-left: 5px;
}

.About-link a {
  width: 100%;
  padding-top: 5px;
}

.About-link::after {
  content: '';
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 1px;
  bottom: 0;
  left: 0;
  background-color: black;
  transform-origin: bottom right;
  transition: transform 0.25s ease-out;
}

.About-link:hover::after {
  transform: scaleX(1);
  transform-origin: bottom left;
}

/* animated link underline */
a:link {
  text-decoration: none;
  position: relative;
  color: inherit;
}

a:visited{
  color:inherit;
  text-decoration: none;
}

a:hover{
  color:inherit;
  text-decoration: none;
}

.Sidebar-link {
  cursor: pointer;
  text-decoration: none;
  position: relative;
  margin: 5px;
}

a::after {
  content: '';
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 1px;
  bottom: 0;
  left: 0;
  background-color: currentColor;
  transform-origin: bottom right;
  transition: transform 0.25s ease-out;
}

a:hover::after {
  transform: scaleX(1);
  transform-origin: bottom left;
}

.Sidebar-link::after {
  content: '';
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: currentColor;
  transform-origin: bottom right;
  transition: transform 0.25s ease-out;
}

.Sidebar-link:hover::after {
  transform: scaleX(1);
  transform-origin: bottom left;
}

.Header-customize {
  bottom: 0;
  position: fixed;
  margin-bottom: 16px;
}

.Customize-button {
  border: 1px solid black;
  background-color: white;
}

.Customize-button:hover + .Customizer-button-sparkle{
  display: block;
}

.Customizer-button-sparkle {
  width: 30px;
  position: absolute;
  bottom: 0;
  right: 0;
  margin-right: -19px;
  margin-bottom: 8px;
  display: none;
}

/* Customizer*/
.Customize-container {
  border: 1px solid;
  bottom: 0;
  margin-bottom: 16px;
  position: fixed;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 6px;
  font-family: 'Satoshi-Regular';
  background-color: white;
  color: black;
  font-size: 12px;
}

input:focus {
  outline: none;
  width: 70px;
  background: transparent;
}

input {
  border: none;
  width: 70px;
  background: transparent;
  font-family: 'Satoshi-Regular';
}

select {
  border-color: black;
  outline: none;
  background: transparent;
}

.Background-picker {
  font-family: 'Satoshi-Regular';
}

.Customizer-done-container {
  display: flex;  
  justify-content: space-between;
  align-items: baseline;
}

.Customizer-done {
  cursor: pointer;
  margin: 0;
  font-family: 'Satoshi-Regular';
  font-size: 18px;
}


.Header-top-mobile {
  display: none;
}

.Nav-mobile {
  display: none;
}

.Sidebar-link-mobile {
  display: none;
}

.Customize-button-mobile {
 display: none;
}

.Customize-mobile {
  display: none;
}
}


/* Mobile */

@media (max-width: 992px) {

  h1 {
    font-family: 'Satoshi-Bold';
  }
  
  h2 {
    font-family: 'Satoshi-Bold';
  }
  
  h3 {
    font-family: 'Satoshi-Bold';
  }
  
  h4 {
    font-family: 'Satoshi-Bold';
  }
  
  p {
    font-family: 'Satoshi-Regular';
  }

  .App {
    height: 100%;
    padding-left: 10px;
    padding-right: 10px;
  }

  .App-header {
    display: flex;
    flex-direction: column;
  }

  .Header-top-mobile h1{
   margin-top: 0px;
   font-size: 1.58em;
   text-align: right;
  }

  .Header-top {
    display: none;
  }

 .Header-container-mobile {
  position: fixed;
  min-width: 95%;
  padding-left: 10px;
  margin-left: -10px;
  padding-right: 10px;
  margin-right: -10px;
  margin-top: -10px;
  padding-top: 10px;
  z-index: 20;
 }
  
  .Header-left {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .Header-customize {
    display: none;
  }

  .Nav-mobile {
    display: flex;
    width: 100%;
    justify-content: space-between;
  }

  .Contact-desktop {
    display: none;
  }

  .Sidebar-link-mobile {
    margin: 0px;
  }

  .Sidebar-link {
    display: none !important; 
  }

  /* Customizer*/
  .Customize-button-mobile {
    z-index: 40;
    height: 20px;
    bottom: 0;
    padding-bottom: 5px;
  }
  
.Customize-mobile {
  margin-top: 35vh;
  padding: 10px;
  width: 90%;
  position: fixed;
  background-color: black;
  color: white;
  font-size: 16px;
  z-index: 60;
}

.Customize-form p{
  margin: 0px;
  color: white;
}

input:focus {
  outline: none;
  width: 70px;
  background: transparent;
}

input {
  border: none;
  width: 70px;
  background: transparent;
  color: white;
}

select {
  border-color: black;
  outline: none;
  background: transparent;
}

.Background-picker {
  font-family: 'Satoshi-Regular';
}

.Customizer-done-container {
  display: flex;  
  justify-content: space-between;
  align-items: baseline;
}

.Customizer-done {
  text-align: center;
}
  
}