@media (min-width: 993px) {

.About {
  margin-top: 13vh;
}
  .About-sections {
    border-top: 1px solid;
    display: grid;
    margin: 0px;
    grid-template-columns: 400px 200px;
    font-family: 'Inter';
  }

  .About-photo {
    height: 75vh;
  }

  .About-intro {
    display: flex;
  }
  
  .About-list {
    list-style-type: none;
  }

  .About-header {
    padding-left: 20px;
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
  }
  
  .grid-item {
    border-bottom: 1px solid;
    margin: 0px;
    padding-top: 20px;
    padding-bottom: 20px;
    list-style-type: none;
  }

  .badges {
    display: flex;
    justify-content: space-between;
  }
}

/* Mobile */
@media (max-width: 992px) {

  .About {
    margin-bottom: 20px;
  }
  .About-photo {
    width: 60vw;
  }

  .About-intro {
    display: flex;
    flex-direction: column-reverse;
    align-items: flex-end;
    justify-content: center;
    padding-top: 120px;
  }
  }