.Extras-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    border: 1px solid;
    margin-top: 21.44px;
    margin-bottom: 21.44px;
}

.Extras-container div {
    aspect-ratio: 1;
}

.Extras-item {
    border: 1px solid;
    display: flex;
    justify-content: center;
    align-items: center;
}

@media (max-width: 992px) {
 .Extras-container {
    margin-top: 140px;
 }

 .Extras-item {
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
}

}

